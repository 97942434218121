import { ErrorLayout } from '@client/components/Scaffolding/ErrorLayout';
import { HTTPStatusCode } from '@sbt-web/networking';
import { ButtonLink } from '@sbt-web/ui';
import dynamic from 'next/dynamic';
import NextError from 'next/error';
import Head from 'next/head';
import React from 'react';

import classes from './styles.module.scss';

const ComputerFlu = dynamic(
  () => import('./assets').then((a) => a.ComputerFlu),
  { ssr: true }
);
const TRexAsset = dynamic(() => import('./assets').then((a) => a.TRexAsset), {
  ssr: true,
});

interface Props {
  statusCode: HTTPStatusCode;
}

const ErrorPage: React.FunctionComponent<Props> = ({ statusCode }) => {
  let title: string, message: React.ReactElement, image: React.ReactElement;

  switch (statusCode) {
    case HTTPStatusCode.BadRequest:
    case HTTPStatusCode.NotFound: {
      title = "Su Subito trovi tutto. Questa pagina, però, non c'è...";
      message = (
        <p>
          Ci dispiace: non siamo riusciti a trovare la pagina che hai richiesto.
          <br />È solo una pagina, per fortuna, non l'affare dei tuoi sogni.
        </p>
      );
      image = <TRexAsset />;
      break;
    }
    case HTTPStatusCode.InternalServerError:
    case HTTPStatusCode.BadGateway:
    case HTTPStatusCode.ServiceUnavailable:
    case HTTPStatusCode.GatewayTimeout: {
      title = 'Si è verificato un errore.';
      message = (
        <p>
          Ti preghiamo di riprovare tra qualche istante. <br /> Se il problema
          dovesse persistere, ti preghiamo di
          <a href="https://assistenza.subito.it/hc/it/">
            <strong> contattare il servizio clienti</strong>
          </a>
        </p>
      );
      image = <ComputerFlu />;

      break;
    }
    default:
      return <NextError statusCode={statusCode} />;
  }

  return (
    <div>
      <Head>
        <title>Subito.it</title>
      </Head>

      <ErrorLayout title={title} message={message} image={image} />
      <div className={classes['btn-container']}>
        <ButtonLink type="outline" href="/">
          Torna in homepage
        </ButtonLink>
      </div>
    </div>
  );
};

export default ErrorPage;
