import {
  AdSenseVariations,
  Channels,
  getChannel,
  LISTING_MSITE,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-middle-lp-tutti',
  Jobs = 'msite-middle-lp-jobs',
  Motor = 'msite-middle-lp-motor',
  Market = 'msite-middle-lp-market',
  RealEstate = 'msite-middle-lp-realestate',
}

const msiteMiddleDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '6030888272',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Middle,
      Channels.Insertion.MsiteTuttiListingMiddle,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '8434780006',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Middle,
      Channels.Vertical.Job,
      Channels.Insertion.MsiteJobsListingMiddle,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '6162928547',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Middle,
      Channels.Vertical.Motors,
      Channels.Insertion.MsiteMotoriListingMiddle,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '9747862309',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Middle,
      Channels.Vertical.Market,
      Channels.Insertion.MsiteMarketListingMiddle,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '1186692828',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Middle,
      Channels.Vertical.RealEstate,
      Channels.Insertion.MsiteRealEstateListingMiddle,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle: Array<ShortConfiguration> = [
  msiteMiddleDefault,
  msiteMiddleJob,
  msiteMiddleMotor,
  msiteMiddleMarket,
  msiteMiddleRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { msiteMiddle, IDs as MsiteMiddleIDs, variations as MsiteMiddle };
