import type { SubitoAction } from '@reducers/common';
import type { AdItem } from '@sbt-web/networking';
import type { Reducer } from 'redux';

enum Types {
  FETCH_ITEM_SUCCESS = 'fetch item success',
}

type ThunkActions = ReturnType<typeof fetchItemSuccess>;

type Actions = ThunkActions;

export type DetailState =
  | {
      item: AdItem;
      loading: false;
    }
  | { item: null; loading: true };

const defaultState: DetailState = {
  item: null,
  loading: true,
};

export const detail: Reducer<DetailState, Actions> = (
  state = defaultState,
  action
): DetailState => {
  switch (action.type) {
    case Types.FETCH_ITEM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export function fetchItemSuccess(
  item: AdItem
): SubitoAction<Types.FETCH_ITEM_SUCCESS, AdItem> {
  return {
    type: Types.FETCH_ITEM_SUCCESS,
    payload: item,
  };
}
