import {
  AdSenseVariations,
  Channels,
  getChannel,
  LISTING_MSITE,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-before-listing-lp-tutti',
  Jobs = 'msite-before-listing-lp-jobs',
  Motor = 'msite-before-listing-lp-motor',
  Market = 'msite-before-listing-lp-market',
  RealEstate = 'msite-before-listing-lp-realestate',
}

const msiteBeforeListingDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '5070414489',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Top,
      Channels.Insertion.MsiteTuttiListingTop,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '7363024837',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Top,
      Channels.Vertical.Job,
      Channels.Insertion.MsiteJobsListingTop,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '2439874217',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Top,
      Channels.Vertical.Motors,
      Channels.Insertion.MsiteMotoriListingTop,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '4495388147',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Top,
      Channels.Vertical.Market,
      Channels.Insertion.MsiteMarketListingTop,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '5808470450',
    channel: getChannel([
      ...LISTING_MSITE,
      Channels.Position.Top,
      Channels.Vertical.RealEstate,
      Channels.Insertion.MsiteRealEstateListingTop,
    ]),
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListing: Array<ShortConfiguration> = [
  msiteBeforeListingDefault,
  msiteBeforeListingJob,
  msiteBeforeListingMotor,
  msiteBeforeListingMarket,
  msiteBeforeListingRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export {
  msiteBeforeListing,
  IDs as MsiteBeforeListingIDs,
  variations as MsiteBeforeListing,
};
